<template>
	<div :ref="name"></div>
</template>

<script>
	import FroalaEditor from 'froala-editor'
	import 'froala-editor/css/froala_editor.pkgd.min.css'
	import 'froala-editor/js/plugins.pkgd.min.js'
	import 'froala-editor/js/languages/zh_cn.js'

	export default {
		props: {
			name: {
				type: String,
				default: 'editor'
			},
			width: {
				type: String,
				default: 'auto'
			},
			heightMin: {
				type: String/Number,
				default: 200
			},
			heightMax: {
				type: String/Number,
				default: 400
			},
			options: {
				type: Object,
				default()
				{
					return {
						language : 'zh_cn',
						width    : this.width,
						heightMin: this.heightMin,
						heightMax: this.heightMax,
						quickInsertEnabled: false,
						toolbarButtons: {
							'moreText': {
								'buttons': [
									'bold',
									'italic',
									'underline',
									'fontSize',
									'textColor',
									'backgroundColor'
								]
							},
							'moreParagraph': {
								'buttons': [
									'alignLeft',
									'alignCenter',
									'alignRight',
									'paragraphFormat',
									'formatOL',
									'formatUL',
									'lineHeight',
									'outdent',
									'indent'
								],
								'buttonsVisible': 4
							},
							'moreRich': {
								'buttons': [
									'insertImage',
									'emoticons'
								]
							},
							'moreMisc': {
								'buttons': [
									'undo',
									'redo',
									'clearFormatting',
									'html',
									'fullscreen'
								],
								'buttonsVisible': 5
							}
						},
						imageInsertButtons     : [ 'imageBack', '|', 'imageUpload', 'imageByURL' ],
						imageEditButtons       : [ 'imageReplace', 'imageAlign', 'imageSize', 'imageRemove' ],
						imageResizeWithPercent : true,
						imageDefaultWidth      : '100%',
						imageDefaultDisplay	   : 'block',
						imageMaxSize           : 1024 * 1024 * 2,
						imageUploadURL         : this.$http.defaults.baseURL + '/upload/froalaEditorImage',
						imageUploadParam       : this.name,
						imageUploadParams      : { width: 1080 },
						requestHeaders         : { Authorization: window.localStorage.getItem('token') },
						events: {
							'contentChanged': () => {
								this.changed()
							},
							'image.uploaded': (response) => {
								return 111
							}
						}
					}
				}
			},
			value: {
				type: String,
				default: ''
			}
		},
		data()
		{
			return {
				editor: null
			}
		},
		mounted()
		{
			this.init()
		},
		methods: {
			init()
			{
				this.editor = new FroalaEditor(this.$refs[this.name], this.options, () => {
					this.editor.html.set(this.value)
				})
			},
			changed()
			{
				this.$emit('input', this.editor.html.get())
			}
		},
		watch: {
			value(newVal, oldVal)
			{
				oldVal || this.editor.html.set(newVal)
			}
		}
	}
</script>